<template>
  <div class=" px-3">
    <div class="row my-2" style="">
      <div class="col-12 d-flex justify-content-center">
        <ImageFileComp
          :selectedImageRoot="getSelectedImageSrc"
          @selectedImageCallback="selectedImage($event)"
        />
      </div>
    </div>
    <div class="image-upload">
      <div>
        <div class="outer" style="position: relative">
          <figure
            class="figureClass"
            ref="figureBlock"
            @mousemove="handleMouseMove"
            @click="getPosition"
          >
            <img
              :src="getImage()"
              :key="getImage()"
              :class="[`pic-${order}`]"
              class="curs"
              :style="getFlipImageCss()"
            />

            <div class="focalPointHover"></div>
          </figure>
          <div
            class="focal-point-picker"
            :style="`top: ${getYCoordinate}%;
    left: ${getXCoordinate}%;`"
          >
            <div class="picketDot"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- getSelectedImageSrc -->

    <div
      class="mt-2 p-3"
      v-if="
        pageDesign.pageStyles.onlyBackgroundImg &&
        pageDesign.pageType == 'resultPage'
      "
    >
      <Button
        class="mb-3 w-100"
        v-if="getSelectedImageSrc !== ''"
        @click="removeBackgroundImage"
        >Remove Background</Button
      >

      <div class="d-flex align-items-center">
        <svg
          width="18"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
            fill="#B3AFB6"
          />
        </svg>

        <input
          type="number"
          min="0"
          v-model="pageDesign.imageBlock.style.imageOpacity"
          style="max-width: 80px"
          class="form-control-sm px-2 ml-2 input-border-style"
        />
      </div>
    </div>
    <div class="p-2 images-size" v-else>
      <div class="">
        <p class="sidebarElementTitle">Image Position</p>

        <div class="row">
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
              :class="
                getImageAlign === 'left' ? 'selected ' : null
              "
              @click="setImageAlign('left')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_154_2545)">
                  <path
                    d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM9.16669 5.83333V14.1667H5.00002V5.83333H9.16669Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_154_2545">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                 getImageAlign === 'left'
                    ? 'color:#4d1b7e'
                    : null
                "
              >
                Left</span
              >
            </div>
          </div>
          <div class="col-6 mb-2" >
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
              :class="
                getImageAlign === 'right'
                  ? 'selected '
                  : null
              "
              @click="setImageAlign('right')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_154_2550)">
                  <path
                    d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.49999C2.27898 17.5 2.06701 17.4122 1.91073 17.2559C1.75445 17.0996 1.66666 16.8877 1.66666 16.6667V3.33333C1.66666 3.11232 1.75445 2.90036 1.91073 2.74408C2.06701 2.5878 2.27898 2.5 2.49999 2.5H17.5ZM16.6667 4.16667H3.33332V15.8333H16.6667V4.16667ZM15 5.83333V14.1667H10.8333V5.83333H15Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_154_2550">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                 getImageAlign === 'end'
                    ? 'color:#4d1b7e'
                    : null
                "
              >
                Right</span
              >
            </div>
          </div>

          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
              :class="
                getImageAlign === 'no' ? 'selected ' : null
              "
              @click="setImageAlign('no')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_154_2556)">
                  <path
                    d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM15 5.83333V14.1667H5.00002V5.83333H15Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_154_2556">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                 getImageAlign === 'no'
                    ? 'color:#4d1b7e'
                    : null
                "
              >
                Background</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="form-group d-flex my-2" v-if="
        Object.prototype.hasOwnProperty.call(
          pageDesign.pageStyles,
          `useMobileImageAlign`
        ) && !isDesktopView" >
        <div
          class="w-100 d-flex align-items-center"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`SeperateuseMobileImageAlign`"
              v-model="pageDesign.pageStyles.useMobileImageAlign"
            />

            <label
              class="custom-control-label"
              :for="`SeperateuseMobileImageAlign`"
              >Use separate Position for Mobile</label
            >
          </div>
        </div>
      </div>


      <div
        class=""
        v-if="
          pageDesign.pageStyles.imageAlign !== 'no' &&
          Object.prototype.hasOwnProperty.call(
            pageDesign.imageBlock,
            `useImageVariableWidth`
          )
        "
      >
        <div class="row">
          <div class="col-12 my-2" v-if="isDesktopView">
            <p class="sidebarElementTitle">Select Width %:</p>
            <div class="form-group d-flex align-items-center">
              <input
                type="range"
                class="form-control-range formControlRange"
                min="20"
                max="50"
                :key="count + 'desktop'"
                v-model="pageDesign.imageBlock.imageVariableWidth"
              />

              <span class="ml-3">{{
                pageDesign.imageBlock.imageVariableWidth
              }}</span>
            </div>
          </div>
          <div class="col-12 my-2" v-else>
            <p class="sidebarElementTitle">Select Height %:</p>
            <div class="form-group d-flex align-items-center">
              <input
                type="range"
                class="form-control-range formControlRange"
                min="20"
                max="50"
                :key="count + 'desktop'"
                v-model="pageDesign.imageBlock.imageVariableMobileHeight"
              />

              <span class="ml-3">{{
                pageDesign.imageBlock.imageVariableMobileHeight
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex my-2 justify-content-center">
        <Button
          v-if="pageDesign.pageStyles.imageAlign == 'no'"
          @click="removeBackgroundImage"
          >Remove Background</Button
        >
        <!-- <button class="btn m-0 p-0 mb-2" v-if="pageDesign.pageStyles.imageAlign == 'no'" @click="removeBackgroundImage"> Remove Background Image</button> -->
      </div>

      <div class=" mb-3">
        <div class="d-flex justify-content-center mb-3">
         

          <Button :btnVariant="'yellow'"  @click="
              pageDesign.imageBlock.style.flipImage =
                !pageDesign.imageBlock.style.flipImage
            ">  <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M15.97 15.25h-2.72c-5.3 0-9.5-2.15-9.5-4.5s4.2-4.5 9.5-4.5c3.03 0 5.82.7 7.62 1.86a.75.75 0 1 0 .81-1.26c-2.06-1.33-5.13-2.1-8.43-2.1-6.02 0-11 2.55-11 6s4.98 6 11 6h2.8l-2.3 2.3a.75.75 0 1 0 1.07 1.05l2.83-2.82c.68-.69.68-1.8 0-2.48l-2.83-2.83a.75.75 0 0 0-1.06 1.06l2.21 2.22z"
              ></path>
            </svg>  Flip Image</Button>
        </div>
        <div class="d-flex align-items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            v-model="pageDesign.imageBlock.style.imageOpacity"
            style="max-width: 80px"
            class="form-control-sm px-2 ml-2 input-border-style"
          />
        </div>
       
      </div>

      <div class="form-group d-flex">
        <div
          class="w-100 d-flex align-items-center"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`SeperateImageForMobile-${Date.now()}`"
              v-model="pageDesign.imageBlock.useMobileImage"
            />

            <label
              class="custom-control-label"
              :for="`SeperateImageForMobile-${Date.now()}`"
              >Use separate Image for Mobile</label
            >
          </div>
        </div>
      </div>
      <div class=" " v-if="pageDesign.pageStyles.imageAlign !== 'no'">
        <p class="sidebarElementTitle">Display</p>

        <div
          class="ml-2 w-100 d-flex"
          role="toolbar"
          aria-label="Toolbar with button groups"
        >
          <div class="show-logo custom-control custom-switch cursor-pointer">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`MobileImagePAge-${Date.now()}`"
              v-model="pageDesign.imageBlock.style.displayMobile"
            />

            <label
              class="custom-control-label"
              :for="`MobileImagePAge-${Date.now()}`"
              >Mobile</label
            >
          </div>
          <div
            class="show-logo custom-control custom-switch cursor-pointer ml-3"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`DesktopImagePAge-${Date.now()}`"
              v-model="pageDesign.imageBlock.style.displayDesktop"
            />

            <label
              class="custom-control-label"
              :for="`DesktopImagePAge-${Date.now()}`"
              >Desktop</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../../Dashboard/Layout/Button.vue";
import ImageFileComp from "../../ImageFileComp.vue";
export default {
  props: {
    pageDesign: Object,
    order: Number,
    isDesktopView: Boolean,
  },
  components: {
    ImageFileComp,
    Button,
  },
  data() {
    return {
      CurrImage: "",
      tempValueX: null,
      tempValueY: null,
      count: 0,
    };
  },
  computed: {
    getSelectedImageSrc() {
      let image;

      if (
        this.pageDesign.pageType &&
        this.pageDesign.pageType == "noResultPage" &&
        this.pageDesign.imageBlock.noResultSrc !== ""
      ) {
        if (this.isDesktopView) {
          image = this.pageDesign.imageBlock.noResultSrc;
        } else {
          image = this.pageDesign.imageBlock.useMobileImage
            ? this.pageDesign.imageBlock.useMobileImageSrc
            : this.pageDesign.imageBlock.noResultSrc;
        }
      } else {
        if (this.isDesktopView) {
          image = this.pageDesign.imageBlock.src;
        } else {
          image = this.pageDesign.imageBlock.useMobileImage
            ? this.pageDesign.imageBlock.useMobileImageSrc
            : this.pageDesign.imageBlock.src;
        }
      }

      return image;
    },

    isResultPageType() {
      if (Object.prototype.hasOwnProperty.call(this.pageDesign, "pageType"))
        return true;
      return false;
    },

    getXCoordinate() {
      if (this.pageDesign.imageBlock.style.xCord) {
        return this.pageDesign.imageBlock.style.xCord;
      } else {
        return 50;
      }
    },
    getYCoordinate() {
      if (this.pageDesign.imageBlock.style.yCord) {
        return this.pageDesign.imageBlock.style.yCord;
      } else {
        return 50;
      }
    },
    isSeperateMobileImage() {
      return !this.isDesktopView && this.pageDesign.imageBlock.useMobileImage;
    },

    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
  },
  methods: {
    getFlipImageCss() {
      return this.pageDesign.imageBlock.style?.flipImage
        ? "transform:scaleX(-1)"
        : null;
    },
    getImage() {
      let baseURL = process.env.VUE_APP_API_BASE_URL;
      let selectedImageSrc = this.getSelectedImageSrc;

      // If no image is selected, set a default image
      if (!selectedImageSrc) {
        this.CurrImage = 'https://images.quizell.com/website/default_start_page.png';
      } else {
        // Check if the image source is a full URL or a relative path
        if (
          selectedImageSrc.startsWith("http://") ||
          selectedImageSrc.startsWith("https://")
        ) {
          this.CurrImage = selectedImageSrc;
        } else {
          // Remove leading slash if exists
          let dbImage = selectedImageSrc.startsWith("/")
            ? selectedImageSrc.substring(1)
            : selectedImageSrc;

          // Construct the full URL
          this.CurrImage = baseURL + dbImage;
        }
      }

      return this.CurrImage;
    },
    backToMainPage() {
      this.$emit("backtomainpage");
    },
    removeBackgroundImage() {
      this.$emit("removeBGImage");
    },
    selectedImage(image) {
      // Function to set the image source based on the condition
      const setImageSource = (sourcePath, useMobile) => {
        const targetProperty = useMobile
          ? "useMobileImageSrc"
          : this.pageDesign.pageType == "noResultPage"
          ? "noResultSrc"
          : "src";
        this.pageDesign.imageBlock[targetProperty] = sourcePath;
      };

      let sourcePath = image;

      // Modify the path based on certain conditions
      if (!this.isResultPageType && image.startsWith("upload")) {
        sourcePath = "/" + image;
      }

      setImageSource(sourcePath, this.isSeperateMobileImage);
    },
    getPosition() {
      this.pageDesign.imageBlock.style.xCord = this.tempValueX;
      this.pageDesign.imageBlock.style.yCord = this.tempValueY;
    },
    handleMouseMove(e) {
      let { left, top, width, height } =
        this.$refs.figureBlock.getBoundingClientRect();
      let { clientX, clientY } = e;
      const posX = clientX - left;
      const posY = clientY - top;
      let CordX = parseInt((posX / width) * 100);
      let CordY = parseInt((posY / height) * 100);

      if (CordY >= 0 && CordY <= 100 && CordX >= 0 && CordX <= 100) {
        this.tempValueX = CordX;
        this.tempValueY = CordY;
      }
    },
    setImageAlign(type) {
      if(this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView){
        this.pageDesign.pageStyles.mobileImageAlign = type
      } else {
        this.pageDesign.pageStyles.imageAlign = type
      }
    },
  },
};
</script>

<style scoped>
.image-upload {
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(227, 227, 227);
  margin-bottom: 16px;
  overflow: hidden;
}
.choose-image-empty {
  padding-bottom: 25px;
  padding-top: 25px;
  background: #f6f6f7;
  border-radius: 10px;
  margin-bottom: 20px;
}
.choose-image-empty > button {
  background: white;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
}
.choose-image-empty:hover {
  border: 1px solid #7f3487;
}
.curs {
  border-radius: 6px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
.images-size label {
  font-size: 14px;
}
.images-size input {
  color: #535355;
}

.figureClass {
  height: 198px;
  line-height: 0;
  margin: 0px;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.figureClass >>> img {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.focalPointHover:hover {
  position: absolute;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease-in-out 0s;
}

.focal-point-picker {
  /* cursor: grab; */
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: top 0.15s ease-out 0s, left 0.15s ease-out 0s;
  background: rgb(4, 135, 175);
  box-shadow: rgb(0 0 0 / 2%) 0px 12px 16px, rgb(0 0 0 / 4%) 0px 8px 14px,
    rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 8%) 0px 2px 4px;
}
</style>
